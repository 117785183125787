import React from 'react';
import AboutUsSec from '../../aboutus/aboutus';
import WhyChooseUs from '../../why-choose-us/why-choose-us';

const AboutUs = () => {
    return ( <>
    <AboutUsSec showButton={false}/>
    <WhyChooseUs />
    </> );
}
 
export default AboutUs;