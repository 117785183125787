import React from 'react';
import { Link } from 'react-router-dom';
import { serviceList } from '../../config/config';

const Services = () => {
    return (<>
        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: 600 }}>
                    <h6 className="section-title bg-white text-center text-primary px-3">Services</h6>
                    <h1 className="display-6 mb-4">Driving Efficiency Through Cutting-Edge IT Services</h1>
                </div>
                <div className="row g-4">

                    {serviceList.map((service, ind) => {

                        return <div key={"service"+ind} className={"col-lg-4 col-md-6 wow fadeInUp " + (ind == 6 ? "offset-lg-2" : "")} data-wow-delay={ "0."+(((ind%3)*2)+1)+"s"}>
                            <Link className={"service-item d-block rounded text-center h-100 p-4"} to={service.url}>
                                <img className="img-fluid rounded mb-4" src={service.image} alt="" />
                                <h4 className="mb-0">{service.name}</h4>
                            </Link>
                        </div>
                    })}
                </div>
            </div>
        </div>
    </>);
}

export default Services;