import React from 'react';

const DetailsSection = ({ details }) => {
    // const getElement = (ele) => {

    //     switch(ele.name) {
    //         case "p":
    //             return <p className={ele.class || ''}>{ele.content}</p>;
    //         case "h1"
    //     }
    // }
    return (<>
        {details.map((ele, i) => {
            if (ele.type == 'services') {
                return <div className="row g-4">
                    {ele.services.map((service, ind) => {
                        return <div key={"service" + ind} className={"col-lg-4 col-md-6 wow fadeInUp " + (ind == 6 ? "offset-lg-2" : "")} data-wow-delay={"0." + (((ind % 3) * 2) + 1) + "s"}>
                            <div className='service-item d-block rounded text-center h-100 p-4'>
                                <img className="img-fluid rounded mb-4" src={service.image} alt="" />
                                <h4 className="mb-0">{service.name}</h4>
                            </div>
                        </div>
                    })}
                </div>
            }
            else if (ele.type == 'steps') {
                return <ul class="list-note">
                    {
                        ele.steps.map((step, ind)=>{
                            return <li key={"step" + ind}>
                            <span>0{ind+1}</span>
                            <div>
                                <h5>{step.title}</h5>
                                <p>{step.description}</p>
                            </div>
                        </li>
                        })
                    }
                </ul>
            }
            else if (ele.type == 'list') {
                return <ul class="">
                    {
                        ele.list.map((list, ind)=>{
                            return <li className='mb-2' key={"list" + ind}>
                            {list}
                        </li>
                        })
                    }
                </ul>
            }
            else {
                return React.createElement(ele.type, ele.props, ele.children)
            }
        })}
    </>);
}

export default DetailsSection;