import React from 'react';

const WebDevelopment = () => {
    return (<>
        <p class="mb-2">A website reflects brand image of a business venture irrespective of its size. A website holds the key for successful running of an online business and it is the most imperative means for portraying one’s brand identity and enticing new prospective clients, clientele, and business associations. One’s website must have the talent to hold visitors and inveigle them to take definite “proceedings”. At World india.com, we start our strategic workflow by getting a hang of your business goals and generate an interactive user – entrepreneur interface that enable us with the experience to effectively design and implement work plans to help achieve your objectives.</p>

        <p class="mb-5">We encourage and nurture creative approaches and mindset in terms of design which appeals to one’s preferred sense of style and technique. Worldindia.com as a web site development company India has the ability to turn all of your imaginations into reality and accelerate the growth of your online business. Our clientele range is wide encompassing small to medium size businesses to large international corporations and everything, which falls in between.</p>
        <h3 class="mb-4">Our Working Process</h3>
        <ul class="list-note">
            <li>
                <span>01</span>
                <div>
                    <h5>Project Research</h5>
                    <p>Understand the purpose of the website, target audience, and main goals. This step involves researching competitors and defining the site’s objectives.</p>
                </div>
            </li>
            <li>
                <span>02</span>
                <div>
                    <h5>Planning & Design</h5>
                    <p>Create a sitemap and wireframes to outline the website’s structure and layout. Develop the visual style of the website, including color schemes, typography, and images.</p>
                </div>
            </li>
            <li>
                <span>03</span>
                <div>
                    <h5>Development</h5>
                    <p>Convert the design into a functional website.</p>
                </div>
            </li>
            <li>
                <span>04</span>
                <div>
                    <h5>Finalization</h5>
                    <p>Test the website for functionality, compatibility, and performance across different devices and browsers.</p>
                </div>
            </li>
        </ul>
    </>);
}

export default WebDevelopment;