import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../navigation/navigation';

const Carousel = ({ title, imageUrl }) => {
    const [bgStyle, setBgStyle] = useState({});
    useEffect(() => {
        window.$('#header-carousel').carousel();
    }, []);

    useEffect(()=>{
        if(imageUrl) {
            setBgStyle({backgroundImage: 'linear-gradient(rgba(0, 0, 0, .8), rgba(0, 0, 0, .4)), url('+imageUrl+')', backgroundPosition: 'center'});
        }
        else {
            setBgStyle({});
        }
    }, [imageUrl]);

    return (<>
        {(!title) ? <div className="container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.1s">
            <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#header-carousel" data-bs-slide-to="0" className="active" aria-label="Slide 1">
                        <img className="img-fluid" src="/assets/img/carousel-1.jpg" alt="Image" />
                    </button>
                    <button type="button" data-bs-target="#header-carousel" data-bs-slide-to="1" aria-label="Slide 2">
                        <img className="img-fluid" src="/assets/img/carousel-2.jpg" alt="Image" />
                    </button>
                    {/* <button type="button" data-bs-target="#header-carousel" data-bs-slide-to="2" aria-label="Slide 3">
                        <img className="img-fluid" src="/assets/img/carousel-1.jpg" alt="Image" />
                    </button> */}
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img className="w-100" src="/assets/img/carousel-1.jpg" alt="Image" />
                        <div className="carousel-caption">
                            <div className="p-3" style={{ maxWidth: 900 }}>
                                <h4 className="text-white text-uppercase mb-4 animated zoomIn">We Are Leader In</h4>
                                <h1 className="display-1 text-white mb-0 animated zoomIn">Creative & Innovative Digital Solution</h1>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img className="w-100" src="/assets/img/carousel-2.jpg" alt="Image" />
                        <div className="carousel-caption">
                            <div className="p-3" style={{ maxWidth: 900 }}>
                                <h4 className="text-white text-uppercase mb-4 animated zoomIn">We Are Leader In</h4>
                                <h1 className="display-1 text-white mb-0 animated zoomIn">Creative & Innovative Digital Solution</h1>
                            </div>
                        </div>
                    </div>
                    {/* <div className="carousel-item">
                        <img className="w-100" src="/assets/img/carousel-1.jpg" alt="Image" />
                        <div className="carousel-caption">
                            <div className="p-3" style={{ maxWidth: 900 }}>
                                <h4 className="text-white text-uppercase mb-4 animated zoomIn">We Are Leader In</h4>
                                <h1 className="display-1 text-white mb-0 animated zoomIn">Creative & Innovative Digital Solution</h1>
                            </div>
                        </div>
                    </div> */}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div> : <div className="container-fluid page-header py-5 mb-5 wow fadeIn" style={bgStyle} data-wow-delay="0.1s">
            <div className="container text-center py-5">
                <h1 className="display-4 text-white animated slideInDown mb-3">{title}</h1>
                <nav aria-label="breadcrumb animated slideInDown">
                    <ol className="breadcrumb justify-content-center mb-0">
                        <li className="breadcrumb-item"><Link className="text-white" to="/">Home</Link></li>
                        <li className="breadcrumb-item text-primary active" aria-current="page">{title}</li>
                    </ol>
                </nav>
            </div>
        </div>
        }
    </>);
}

export default Carousel;