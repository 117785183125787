import React from 'react';
import AboutUs from '../../aboutus/aboutus';
import Services from '../../services/services';
import WhyChooseUs from '../../why-choose-us/why-choose-us';
import OurProjects from '../../our-projects/our-projects';
import Contact from '../../contact/contact';

const Home = () => {
    return ( <>
        <AboutUs showButton={true}/>
        <Services/>
        <WhyChooseUs/>
        <Contact hideMap={true}/>
    </> );
}
 
export default Home;