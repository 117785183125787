import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import config, { serviceList } from '../../config/config';

const Navigation = () => {
    return (<><div className="container-fluid py-4 px-5 wow fadeIn" data-wow-delay="0.1s" style={{ visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeIn' }}>
        <div className="row align-items-center top-bar">
            <div className="col-lg-4 col-md-12 text-center text-lg-start">
                <a href="" className="navbar-brand m-0 p-0">
                    {/* <h1 className="fw-bold text-primary m-0"><i className="fa fa-laptop-code me-3"></i>DGcom</h1> */}
                    <img src="/assets/img/logo.png" />
                </a>
            </div>
            <div className="col-lg-8 col-md-7 d-none d-lg-block">
                <div className="row">
                    <div className="col-4">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="d-flex align-items-center justify-content-center btn-lg-square border rounded-circle">
                                <i className="far fa-clock text-primary"></i>
                            </div>
                            <div className="ps-3">
                                <p className="mb-2">Working Hours</p>
                                <a href={config.addressLink} target='_blank'><h6 className="mb-0">Mon - Fri, 9:00 - 17:00</h6></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="d-flex align-items-center justify-content-center btn-lg-square border rounded-circle">
                                <i className="fa fa-phone text-primary"></i>
                            </div>
                            <div className="ps-3">
                                <p className="mb-2">Call Us</p>
                                <a href={"tel:" + config.contactNumber}><h6 className="mb-0">{config.contactNumber}</h6></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="d-flex align-items-center justify-content-center btn-lg-square border rounded-circle">
                                <i className="far fa-envelope text-primary"></i>
                            </div>
                            <div className="ps-3">
                                <p className="mb-2">Email Us</p>
                                <a href={"mailto:" + config.email}><h6 className="mb-0">{config.email}</h6></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

        <nav className="transparent-nav w-100 navbar navbar-expand-lg bg-primary navbar-dark sticky-top py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s">
            <a href="#" className="navbar-brand ms-3 d-lg-none">{config.appShortName}</a>
            <button type="button" className="navbar-toggler me-3" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-expanded="true">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="navbar-collapse collapse" id="navbarCollapse">
                <div className="navbar-nav me-auto p-3 p-lg-0">
                    <NavLink to="/" className="nav-item nav-link">Home</NavLink>
                    <NavLink to="/about" className="nav-item nav-link">About Us</NavLink>
                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Services</a>
                        <div className="dropdown-menu border rounded-0 rounded-bottom m-0">
                            {serviceList.map((service, ind)=>{
                                return <Link key={"menuService"+ind} to={service.url} className={"dropdown-item " + (service.bold ? " fw-bold" : "")}>{service.name}</Link>
                            })}
                        </div>
                    </div>
                    {/* <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Projects</a>
                        <div className="dropdown-menu border rounded-0 rounded-bottom m-0">
                            <a href="project.html" className="dropdown-item">Project Grid</a>
                            <a href="project-detail.html" className="dropdown-item">Project Detail</a>
                        </div>
                    </div>
                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Blogs</a>
                        <div className="dropdown-menu border rounded-0 rounded-bottom m-0">
                            <a href="blog.html" className="dropdown-item">Blog Grid</a>
                            <a href="blog-detail.html" className="dropdown-item">Blog Detail</a>
                        </div>
                    </div>
                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                        <div className="dropdown-menu border rounded-0 rounded-bottom m-0">
                            <a href="price.html" className="dropdown-item">Pricing Plan</a>
                            <a href="feature.html" className="dropdown-item">Features</a>
                            <a href="team.html" className="dropdown-item">Our Team</a>
                            <a href="testimonial.html" className="dropdown-item">Testimonial</a>
                            <a href="404.html" className="dropdown-item">404 Page</a>
                            <a href="contact.html" className="dropdown-item">Contact Us</a>
                        </div>
                    </div> */}
                    <NavLink to="/contact" className="nav-item nav-link">Contact Us</NavLink>
                </div>
                {/* <a href="https://htmlcodex.com/web-design-agency-html-template-pro" className="btn btn-sm btn-outline-light rounded-pill border-2 py-2 px-4 d-none d-lg-block">Purchase Now</a> */}
            </div>
        </nav>
    </>);
}

export default Navigation;