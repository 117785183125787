import React from 'react';
import config from '../../config/config';
import { Link } from 'react-router-dom';
import './footer.css';

const Footer = () => {
    return (<>
        <div className="container-fluid bg-dark text-body footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-4 col-md-6">
                        <h5 className="text-light mb-4">Address</h5>
                        <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i><a className='text-secondary' href={config.addressLink} target='_blank'>{config.displayAddress}</a></p>
                        <p className="mb-2"><i className="fa fa-phone-alt me-3"></i><a className='text-secondary' href={"tel:" + config.contactNumber}>{config.contactNumber}</a></p>
                        <p className="mb-2"><i className="fa fa-envelope me-3"></i><a href={"mailto:" + config.email}>{config.email}</a></p>
                        <div className="d-flex pt-2">
                            <a className="btn btn-square btn-outline-secondary rounded-circle me-1" href><i className="fab fa-x-twitter"></i></a>
                            <a className="btn btn-square btn-outline-secondary rounded-circle me-1" href><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-square btn-outline-secondary rounded-circle me-1" href><i className="fab fa-instagram"></i></a>
                            <a className="btn btn-square btn-outline-secondary rounded-circle me-0" href><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h5 className="text-light mb-4">Quick Links</h5>
                        <Link className="btn btn-link" to="/">Home</Link>
                        <Link className="btn btn-link" to="/about">About Us</Link>
                        <Link className="btn btn-link" to="/contact">Contact Us</Link>
                        <Link className="btn btn-link" to="/services">Our Services</Link>
                        <Link className="btn btn-link d-none">Support</Link>
                    </div>
                    {/* <div className="col-lg-4 col-md-6">
                        <h5 className="text-light mb-4">Gallery</h5>
                        <div className="row g-2">
                            <div className="col-4">
                                <img className="img-fluid rounded" src="img/project-1.jpg" alt="Image"/>
                            </div>
                            <div className="col-4">
                                <img className="img-fluid rounded" src="img/project-2.jpg" alt="Image"/>
                            </div>
                            <div className="col-4">
                                <img className="img-fluid rounded" src="img/project-3.jpg" alt="Image"/>
                            </div>
                            <div className="col-4">
                                <img className="img-fluid rounded" src="img/project-4.jpg" alt="Image"/>
                            </div>
                            <div className="col-4">
                                <img className="img-fluid rounded" src="img/project-5.jpg" alt="Image"/>
                            </div>
                            <div className="col-4">
                                <img className="img-fluid rounded" src="img/project-6.jpg" alt="Image"/>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-lg-4 col-md-6 d-none">
                        <h5 className="text-light mb-4">Newsletter</h5>
                        <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
                        <div className="position-relative mx-auto" style={{ maxWidth: 400 }}>
                            <input className="form-control bg-transparent border-secondary w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" />
                            <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
                        </div>
                    </div>

                    <div className="col-lg-5 col-md-6">
                        {/* <h5 className="text-light mb-4">{config.appName}</h5> */}
                        <Link to="/"><img src="/assets/img/logo.png" className='footer-logo' /></Link>
                        <p className='mt-4'>Our mission is to empower businesses by delivering top-notch IT services that drive growth and success. We believe in the power of technology to create meaningful connections and enhance user experiences.</p>
                    </div>

                </div>
            </div>
            <div className="container-fluid copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                            &copy; <a href="#">Navmukh</a>, All Right Reserved.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a target="_blank" href={"https://api.whatsapp.com/send/?phone="+config.contactNumber.replace(/-/g,"").replace("+","")+"&text=Hi%2C+I+wanted+to+know+more+about&type=phone_number&app_absent=0"} class="btn btn-lg btn-primary btn-lg-square rounded-circle fixed-wa-icon"><img src="/assets/img/whatsapp-sm.png" alt="whatsapp" /></a>
    </>);
}

export default Footer;