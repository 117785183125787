export const servicePageDetails = {
    "software-development": {
        structure: [
            {
                type: 'p',
                props: {
                    className: 'mb-2'
                },
                children: "In the fast-paced, ever-evolving world of technology, our software development services stand out. With a passion for innovation and a dedication to excellence, we craft bespoke solutions tailored to meet your unique business needs."
            },
            {
                type: 'p',
                props: {
                    className: 'mb-5'
                },
                children: "Our team of expert developers leverages cutting-edge technologies to deliver robust, scalable, and user-friendly applications that drive success. Whether it's web, mobile, or enterprise software, we turn your ideas into reality, ensuring seamless functionality and a superior user experience. Partner with us, and let's create the future together."
            },
            {
                type: 'h3',
                props: {
                    className: 'mb-4'
                },
                children: 'Our Working Process'
            },
            {
                type: 'steps',
                steps: [
                    {
                        title: 'Conceptualization',
                        description: 'Defining the project goals and requirements.'
                    },
                    {
                        title: 'Design & Development',
                        description: 'Creating the architecture and writing the code.'
                    },
                    {
                        title: 'Testing',
                        description: 'Ensuring the software works flawlessly.'
                    },
                    {
                        title: 'Deployment & Maintenance',
                        description: 'Launching the software and keeping it updated.'
                    }
                ]
            }
        ]
    },
    "graphic-design": {
        structure: [
            {
                type: 'p',
                props: {
                    className: 'mb-2'
                },
                children: "Unlock the power of visual storytelling with our innovative graphic design solutions. We blend creativity and strategy to deliver designs that captivate and engage your audience."
            },
            {
                type: 'p',
                props: {
                    className: 'mb-2'
                },
                children: 'Our approach focuses on crafting unique, memorable visuals that reflect your brand’s identity and communicate your message effectively. From striking logos and compelling marketing materials to dynamic web graphics and social media content, our designs are tailored to make a lasting impression.'
            },
            {
                type: 'p',
                props: {
                    className: 'mb-5'
                },
                children: 'Collaborate with us to elevate your brand with high-quality, impactful graphics that stand out in a crowded marketplace. Experience design excellence that not only looks great but also drives results.'
            },
            {
                type: 'h3',
                props: {
                    className: 'mb-4'
                },
                children: 'Services We Offer'
            },
            {
                type: 'services',
                services: [
                    {
                        name: "Logo Design & Branding",
                        image: "/assets/img/services/graphic/design-thinking.png"
                    },
                    {
                        name: "Print & Layout Design",
                        image: "/assets/img/services/graphic/magazine.png"
                    },
                    {
                        name: "Infrographics Design",
                        image: "/assets/img/services/graphic/infographic.png"
                    },
                    {
                        name: "Brochures",
                        image: "/assets/img/services/graphic/brochure.png"
                    },
                    {
                        name: "Business Card",
                        image: "/assets/img/services/graphic/business-card.png"
                    },
                    {
                        name: "Design Thinking",
                        image: "/assets/img/services/graphic/design-thinking.png"
                    }
                ]
            }
        ]
    },
    "home-automation": {
        structure: [
            {
                type: 'p',
                props: {
                    className: 'mb-2'
                },
                children: "Elevate your home with our state-of-the-art home automation systems. Imagine controlling your entire household with a simple touch or voice command. Lights dimmed for movie night, thermostat adjusted for the perfect sleep temperature, doors locked for peace of mind—all without lifting a finger. Our custom solutions are designed to fit seamlessly into your lifestyle, enhancing comfort, security, and energy efficiency."
            },
            {
                type: 'p',
                props: {
                    className: 'mb-2'
                },
                children: "We don't just install; we transform your living experience. With our expert team, you'll have a smart home that anticipates your needs and adapts to your preferences. From smart lighting and climate control to advanced security systems and entertainment setups, we bring the future to your home today."
            },
            {
                type: 'p',
                props: {
                    className: 'mb-5'
                },
                children: "Welcome to the new era of living—intuitive, integrated, and incredibly convenient."
            }
        ]
    },
    "digital-marketing": {
        structure: [
            {
                type: 'p',
                props: {
                    className: 'mb-2'
                },
                children: "In today’s digital era, establishing a powerful online presence is essential for business success. Our digital marketing services are designed to amplify your brand’s reach and engage your target audience effectively. From SEO and content marketing to social media management and PPC advertising, we employ data-driven strategies to maximize your ROI. Our expert team tailors campaigns to your unique needs, ensuring your message cuts through the noise and resonates with your audience."
            },
            {
                type: 'p',
                props: {
                    className: 'mb-2'
                },
                children: "Let us help you turn digital potential into real-world results."
            },
            {
                type: 'p',
                props: {
                    className: 'mb-5'
                },
                children: "Ready to take your brand to the next level? Let's make it happen."
            },
            {
                type: 'services',
                services: [
                    {
                        name: 'Search Engine Optimization',
                        image: '/assets/img/services/digital-marketing/SEO.jpeg'
                    },
                    {
                        name: 'Email Marketing',
                        image: '/assets/img/services/digital-marketing/email-m.jpeg'
                    }, {
                        name: 'Social Media Optimization',
                        image: '/assets/img/services/digital-marketing/social-media.jpg'
                    },
                    {
                        name: 'Google AdWords',
                        image: '/assets/img/services/digital-marketing/google-ad.jpg'
                    },
                    {
                        name: 'Content Creation',
                        image: '/assets/img/services/digital-marketing/content-creation.jpeg'
                    }
                ]
            }
        ]
    },
    "cctv-surveillance": {
        structure: [
            {
                type: 'p',
                props: {
                    className: "mb-2"
                },
                children: "Safeguard your property with our advanced CCTV surveillance systems. Our state-of-the-art technology provides crystal-clear video capture, real-time monitoring, and seamless remote access, ensuring that you stay connected to your security 24/7. Whether you're protecting your home, office, or industrial site, our bespoke solutions are designed to meet your unique security needs."
            },
            {
                type: 'p',
                props: {
                    className: "mb-2"
                },
                children: "From installation to maintenance, our team of experts handles every aspect with precision and professionalism. Experience peace of mind knowing that our surveillance systems offer robust protection against intrusions and other threats."
            },
            {
                type: 'h3',
                props: {
                    className: "mb-4"
                },
                children: "Features"
            },
            {
                type: "list",
                list: [
                    "High-definition video capture for clear and detailed footage",
                    "Real-time monitoring with instant alerts",
                    "Remote access via mobile app for on-the-go surveillance",
                    "Night vision capabilities for round-the-clock security",
                    "Motion detection to minimize false alarms",
                    "Cloud storage for secure and accessible video archives",
                    "Weatherproof cameras for outdoor use",
                    "Integration with other security systems for comprehensive protection"
                ]
            },
            {
                type: 'p',
                props: {
                    className: "mb-5 mt-5"
                },
                children: "Stay secure with our top-tier surveillance systems."
            }
        ]
    },
    "home-theater": {
        structure: [
            {
                type: 'p',
                props: {
                    className: "mb-2"
                },
                children: "Transform your living room into a cinematic oasis with our premium home theater systems. Experience breathtaking visuals and immersive audio that brings your favorite movies and shows to life. Our customized solutions cater to your unique space and preferences, ensuring optimal sound and picture quality."
            },
            {
                type: 'p',
                props: {
                    className: "mb-5"
                },
                children: "Our experts handle everything from design and installation to fine-tuning the system, creating a seamless, hassle-free experience. With our state-of-the-art technology and professional service, you can enjoy the ultimate entertainment experience right in the comfort of your home."
            },
            {
                type: 'h3',
                props: {
                    className: "mb-4"
                },
                children: "Features"
            },
            {
                type: "list",
                list: [
                    "Dolby Atmos surround sound for an immersive audio experience",
                    "4K Ultra HD resolution for crystal-clear visuals",
                    "Smart home integration for seamless control",
                    "Acoustic treatments to optimize sound quality",
                    "Wireless connectivity for a clutter-free setup",
                    "Voice-activated controls for ultimate convenience",
                ]
            },
            {
                type: 'p',
                props: {
                    className: "mb-5 mt-5"
                },
                children: "Bring the magic of the big screen to your home."
            }
        ]
    },
    "networking": {
        structure: [
            {
                type: 'p',
                props: {
                    className: "mb-5"
                },
                children: "Ensure seamless connectivity with our top-notch networking services. We design, implement, and maintain robust network infrastructures tailored to your business needs. Our solutions guarantee optimal performance, security, and scalability, whether for small offices or large enterprises."
            },
            {
                type: 'h3',
                props: {
                    className: "mb-4"
                },
                children: "Our expert team offers:"
            },
            {
                type: "list",
                list: [
                    "Comprehensive network design and architecture",
                    "Secure and reliable connections",
                    "Scalability to grow with your business",
                    "Proactive monitoring and maintenance",
                    "24/7 support for uninterrupted operations"
                ]
            },
            {
                type: 'p',
                props: {
                    className: "mb-5"
                },
                children: ""
            },
            {
                type: 'h3',
                props: 'mb-4',
                children: "In addition, we leverage the latest advancements in networking technology to deliver:"
            },
            {
                type: "list",
                list: [
                    "Enhanced data security and encryption",
                    "High-speed connectivity for improved efficiency",
                    "Redundant systems for maximum uptime",
                    "Custom solutions to fit your unique requirements",
                    "Detailed analytics and reporting for network performance"
                ]
            },
            {
                type: 'p',
                props: {
                    className: "mb-5 mt-5"
                },
                children: "Stay connected and empower your business with our reliable networking solutions."
            },
        ]
    }
}