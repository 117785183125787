import React from 'react';

const WhyChooseUs = () => {
    return ( <>
        <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-5">
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="h-100">
                            <h6 className="section-title bg-white text-start text-primary pe-3">Why Choose Us</h6>
                            <h1 className="display-6 mb-4">Why People Trust Us? Learn About Us!</h1>
                            <ul>
                                <li><span className='fw-bold'>Expert Team</span>: Our team of skilled professionals brings years of experience and a passion for technology to every project.</li>
                                <li><span className='fw-bold'>Customer-Centric Approach</span>: We prioritize your needs and work closely with you to achieve your goals.</li>
                                <li><span className='fw-bold'>Innovative Solutions</span>: We stay ahead of industry trends to provide you with the latest and most effective solutions.</li>
                                <li><span className='fw-bold'>Proven Track Record</span>: With a portfolio of successful projects and satisfied clients, we have a history of delivering results.</li>
                                <li><span className='fw-bold'>Comprehensive Support</span>: From initial consultation to post-launch support, we are with you every step of the way.</li>
                                <li><span className='fw-bold'>Cost-Effective Services</span>: We offer competitive pricing without compromising on quality, ensuring you get the best value for your investment.</li>
                                <li><span className='fw-bold'>Scalable Solutions</span>: Our services are designed to grow with your business, providing flexibility and scalability as your needs evolve.</li>
                            </ul>
                            <p className='mb-4'>Join us on a journey to digital excellence. Let’s build something amazing together!</p>
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="img-border">
                            <img className="img-fluid" src="/assets/img/why-choose-us.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </> );
}
 
export default WhyChooseUs;