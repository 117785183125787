import logo from './logo.svg';
import './App.css';
import Header from './components/header/header';
import Home from './components/pages/home/home';
import Footer from './components/footer/footer';
import { HashRouter, Routes, Route } from 'react-router-dom';
import AboutUs from './components/pages/aboutus/aboutus';
import Contact from './components/contact/contact';
import Services from './components/services/services';
import ServiceDetails from './components/service-details/service-details';

function App() {
  return (
    <>
      <HashRouter>
        <Header />
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/about' element={<AboutUs />}></Route>
          <Route path='/contact' element={<Contact />}></Route>
          <Route path='/services' element={<Services />}></Route>
          <Route path='/service/*' element={<ServiceDetails />}></Route>
        </Routes>
        <Footer />
      </HashRouter>
    </>
  );
}

export default App;
