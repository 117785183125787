const config = {
    contactNumber: "+1-778-592-7757",
    displayAddress: "Unit #:201-D, 7337 137 Street, Surrey, BC V3W 1A4",
    addressLink: "https://maps.app.goo.gl/N45MmzoW3iKT5tJD6",
    email: "info@navmukh.com",
    communicationEmail: "",
    appName: "Navmukh Enterprises",
    appShortName: "Navmukh",
};
export default config;

export const pageTitles = {
    "/": "",
    "/contact": "Contact Us",
    "/about": "About Us",
    "/services": "Our Services",
    "/service/web-development": "Website Development",
    "/service/graphic-design": "Graphic Design",
    "/service/networking": "Networking",
    "/service/home-automation": "Home Automation System",
    "/service/digital-marketing": "Digital Marketing",
    "/service/software-development": "Software Development",
    "/service/cctv-surveillance": "CCTV Surveillance System",
    "/service/home-theater": "Home Theater System",
}

export const serviceList = [
    {
        name: "Website Development",
        image: "/assets/img/services/web-development.jpg",
        bold: false,
        url: "/service/web-development",
        detailPageImg: ""
    },
    {
        name: "Graphic Design",
        image: "/assets/img/services/graphic-design.jpg",
        bold: false,
        url: "/service/graphic-design",
        detailPageImg: ""
    },
    {
        name: "Networking",
        image: "/assets/img/services/networking.jpg",
        bold: false,
        url: "/service/networking",
        detailPageImg: ""
    },
    {
        name: "Home Automation System",
        image: "/assets/img/services/HAS.jpg",
        bold: false,
        url: "/service/home-automation",
        detailPageImg: "/assets/img/services/HAS-Detail.jpg"
    },
    {
        name: "Digital Marketing",
        image: "/assets/img/services/email-marketing.jpg",
        bold: false,
        url: "/service/digital-marketing",
        detailPageImg: "/assets/img/services/digital-marketing-detail.jpg"
    },
    {
        name: "Software Development",
        image: "/assets/img/services/app-development.jpg",
        bold: false,
        url: "/service/software-development",
        detailPageImg: ""
    },
    {
        name: "CCTV Surveillance System",
        image: "/assets/img/services/CCTV.jpg",
        bold: false,
        url: "/service/cctv-surveillance",
        detailPageImg: "/assets/img/services/CCTV-detail.jpg"
    },
    {
        name: "Home Theater System",
        image: "/assets/img/services/HTS.jpg",
        bold: true,
        url: "/service/home-theater",
        detailPageImg: "/assets/img/services/HTS-detail.jpg"
    },

]
