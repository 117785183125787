import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router';
import WebDevelopment from './web-development/web-development';
import config, { serviceList } from '../../config/config';
import { Link } from 'react-router-dom';
import Contact from '../contact/contact';
import { servicePageDetails } from './servicesPageDetails';
import DetailsSection from './details-section/DetailsSection';
import './service-details.css';

const ServiceDetails = () => {
    const loc = useLocation();
    const [selService, setSelService] = useState({});
    const [details, setDetails] = useState(null);

    useEffect(()=>{
        const ser = serviceList.find(x=>x.url == loc.pathname);
        if(ser)
            setSelService(ser);
        setDetails(servicePageDetails[loc.pathname.replace('/service/', '')]);
    }, [loc]);

    return (<>
        <div class="container-xxl py-5">
            <div class="container">
                <div class="row g-5">

                    <div class="col-lg-8 wow fadeInUp" data-wow-delay="0.1s">
                        {/* <div id="detail-carousel" class="carousel slide mb-5" data-bs-ride="carousel">
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img class="w-100" src="img/carousel-1.jpg" alt="Image" />
                                </div>
                                <div class="carousel-item">
                                    <img class="w-100" src="img/carousel-2.jpg" alt="Image" />
                                </div>
                            </div>
                            <button class="carousel-control-prev" type="button" data-bs-target="#detail-carousel" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#detail-carousel" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div> */}
                        {selService.detailPageImg ? <img className='w-100 mb-5' src={selService.detailPageImg} alt={selService.name} /> : null}
                        <h1 class="display-6 mb-4">{selService.name}</h1>
                        <Routes>
                            <Route path="/web-development" element={<WebDevelopment />}></Route>
                        </Routes>
                        {
                            details ? <DetailsSection details={details.structure} /> : null
                        }
                    </div>

                    <div class="col-lg-4">

                        <div class="bg-light rounded p-4 mb-5 wow fadeInUp" data-wow-delay="0.1s">
                            <h3 class="mb-4">Other Services</h3>
                            <div class="service-list d-flex flex-column">
                                {serviceList.filter(x => x.url != loc.pathname).map((service, ind) => {
                                    return <Link key={"sideMenuService" + ind} to={service.url} className={"bg-white text-body " + (service.bold ? " fw-bold" : "")}>{service.name}</Link>
                                })}
                            </div>
                        </div>


                        <div class="bg-light rounded p-4 mb-5 wow fadeInUp" data-wow-delay="0.1s">
                            <h3 class="mb-4">Need Any Help?</h3>
                            <div class="bg-white rounded p-4 mb-2">
                                <p class="mb-2"><i class="fa fa-phone text-primary me-3"></i>Call Us Now</p>
                                <h5 class="mb-0"><a className='text-dark' href={"tel:" + config.contactNumber}>{config.contactNumber}</a></h5>
                            </div>
                            <div class="bg-white rounded p-4">
                                <p class="mb-2"><i class="fa fa-envelope-open text-primary me-3"></i>Mail Us Now</p>
                                <h5 class="mb-0"><a href={"mailto:" + config.email}>{config.email}</a></h5>
                            </div>
                        </div>


                        <div class="d-none bg-light rounded p-4 mb-5 wow fadeInUp" data-wow-delay="0.1s">
                            <h3 class="mb-4">Appointment</h3>
                            <p class="mb-4">Are you interested in this service? Make an appointment.</p>
                            <a class="btn btn-white w-100 py-3" href="appointment.html">Make An Appointment</a>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <Contact hideMap={true}/>
    </>);
}

export default ServiceDetails;