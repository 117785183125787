import React, { useEffect, useState } from 'react';
import Navigation from '../navigation/navigation';
import Carousel from '../carousel/carousel';
import {useLocation} from 'react-router-dom';
import { pageTitles, serviceList } from '../../config/config';

const Header = () => {
    const location = useLocation();
    const [title, setTitle] = useState('');
    const [serviceDetail, setServiceDetails] = useState(null);
    useEffect(()=>{
        // change it to be dynamic
        setTitle(pageTitles[location.pathname]);
        window.scrollTo(0,0);
        const navbar = document.getElementById('navbarCollapse');
        if(navbar) {
            navbar.classList.remove('show');
        }

        setServiceDetails(serviceList.find(service=>service.url == location.pathname));

    }, [location]);

    return ( <>
    <Navigation></Navigation>
    <Carousel title={title} imageUrl={serviceDetail ? serviceDetail.image : null}></Carousel>
    </> );
}
 
export default Header;