import React, { useState } from 'react';
import { EmailService } from '../../services/EmailService';

const Contact = ({hideMap}) => {
    const [message, setMessage] = useState('');

    const submitContactUs = (event) => {
        event.preventDefault();
        setMessage('');
        const form = document.forms['contactForm'];
        const data = {
            name: form.name.value, 
            phone: form.phone.value, 
            email: form.email.value, 
            subject: form.subject.value, 
            message: form.message.value
        }
        EmailService.sendEmail(data).then(res=>{
            setMessage('Thank you for contacting us. We will get back to you shortly!');
            form.reset();
        }).catch(res=>{
            setMessage('An error occurred, please try again later!');
        })
    }

    return (<>
        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: 600, visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp' }}>
                    <h6 className="section-title bg-white text-center text-primary px-3">Contact Us</h6>
                    <h1 className="display-6 mb-4">We’re Here to Help You Thrive in the Digital World</h1>
                </div>
                <div className="row g-0 justify-content-center">
                    <div className="col-lg-8 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="contact-form">
                            <div id="alertMessage"></div>
                            <form id="contactForm" onSubmit={submitContactUs} autoComplete='off'>
                                <div className="row gx-3">
                                    <div className="col-md-6 control-group">
                                        <div className="form-floating">
                                            <input type="text" className="form-control" id="name" name="name" placeholder="Your Name" required="required" data-validation-required-message="Please enter your name" />
                                            <label htmlFor="name">Your Name</label>
                                        </div>
                                        <p className="help-block text-danger"></p>
                                    </div>
                                    <div className="col-md-6 control-group">
                                        <div className="form-floating">
                                            <input type="number" className="form-control" id="phone" name="phone" placeholder="Your Phone No." required="required" data-validation-required-message="Please enter your phone number" />
                                            <label htmlFor="email">Your Phone No.</label>
                                        </div>
                                        <p className="help-block text-danger"></p>
                                    </div>
                                    <div className="col-md-12 control-group">
                                        <div className="form-floating">
                                            <input type="email" className="form-control" id="email" name="email" placeholder="Your Email" required="required" data-validation-required-message="Please enter your email" />
                                            <label htmlFor="email">Your Email</label>
                                        </div>
                                        <p className="help-block text-danger"></p>
                                    </div>
                                    <div className="col-12 control-group">
                                        <div className="form-floating">
                                            <input type="text" maxLength="200" className="form-control" id="subject" name="subject" placeholder="Subject" required="required" data-validation-required-message="Please enter a subject" />
                                            <label htmlFor="subject">Subject</label>
                                        </div>
                                        <p className="help-block text-danger"></p>
                                    </div>
                                    <div className="col-12 control-group">
                                        <div className="form-floating">
                                            <textarea className="form-control" placeholder="Leave a message here" id="message" name="message" required="required" data-validation-required-message="Please enter your message" style={{ height: 165 }}></textarea>
                                            <label htmlFor="message">Message</label>
                                        </div>
                                        <p className="help-block text-danger"></p>
                                    </div>
                                    <div className="col-12 text-center">
                                        <button className="btn btn-primary rounded-pill py-3 px-5" type="submit" id="sendMessageButton">
                                            <span>Send Message</span>
                                            <div className="d-none spinner-border spinner-border-sm text-light ms-3" role="status"></div>
                                        </button>
                                    </div>
                                    {message ? <div className="col-12 text-center mt-2">
                                        <p className='text-primary'>{message}</p>
                                    </div> : null}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {hideMap ? null : <div class="container-xxl pt-5 px-0 wow fadeIn" data-wow-delay="0.1s">
            <iframe class="w-100 mb-n2" style={{height: 450}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2610.3965077653434!2d-122.84581802312104!3d49.136095681122754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485da2dd8300001%3A0xd45d3cc0dd898856!2s7337%20137%20St%20%23201D%2C%20Surrey%2C%20BC%20V3W%201A4!5e0!3m2!1sen!2sca!4v1728588922585!5m2!1sen!2sca" frameborder="0" allowfullscreen aria-hidden="false" tabindex="0"></iframe>
        </div>}
    </>);
}

export default Contact;