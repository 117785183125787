import React from 'react';
import {Link} from 'react-router-dom';
import config from '../../config/config';

const AboutUs = ({showButton}) => {
    return (<>
        <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-5">
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{ visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp' }}>
                        <div className="img-border">
                            <img className="img-fluid" src="https://demo.htmlcodex.com/pro/dgcom/img/about.jpg" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s" style={{ visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp' }}>
                        <div className="h-100">
                            <h6 className="section-title bg-white text-start text-primary pe-3">About Us</h6>
                            <h1 className="display-6 mb-4">Empowering Innovation: Your <span className="text-primary">Trusted Partner</span> in Cutting-Edge IT Solutions</h1>
                            <p>Welcome to {config.appName}, where innovation meets excellence. We are a dynamic IT solutions provider dedicated to transforming your digital presence through cutting-edge technology and creative design.</p>
                            <p className="mb-4">At {config.appName}, our mission is to empower businesses by delivering top-notch IT services that drive growth and success. We believe in the power of technology to create meaningful connections and enhance user experiences.</p>
                            <div className="d-flex align-items-center mb-4 pb-2 d-none">
                                <img className="flex-shrink-0 rounded-circle" src="/assets/img/about.jpg" alt="" style={{ width: 50, height: 50 }} />
                                <div className="ps-4">
                                    <h6>Jhon Doe</h6>
                                    <small>SEO &amp; Founder</small>
                                </div>
                            </div>
                            {showButton ? <Link to="/about" className="btn btn-primary rounded-pill py-3 px-5" href="">Read More</Link> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default AboutUs;